body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* full calendar */

.fc-v-event {
  margin: 0 10px;
  border-radius: 10px !important;
  padding: 7px 5px;
}

.fc .fc-daygrid-day.fc-day-today {
  border-radius: 10px;
}

.fc .fc-timegrid-slot-minor {
  /*border: none;*/
}

.fc-theme-standard td, .fc-theme-standard th, .fc-theme-standard .fc-scrollgrid {
  border: none !important;
}

.fc-col-header {
  margin-bottom: 10px;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end{
  padding: 10px;
  border-radius: 50px;
}

/*.fc-highlight {
  border: 3px solid;
  border-radius: 3px;
}*/